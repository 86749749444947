<template>
  <div class="view">
    <div class="footer-a">
      <div class="footer-content">
        <div>
          <div class="logo-2">
            <img src="@/assets/image/logo(2).png" alt="">
          </div>
          <div>
            <div style="margin-bottom: 28px">联系方式</div>
            <div style="margin-bottom: 10px">
              电话:027-8730 1288 / 177-4050-2025
            </div>
            <div style="margin-bottom: 10px">
              地址:湖北省武汉市洪山区邮科院路特1号湖北信息产业科技大厦10楼02号
            </div>
            <div>
              邮箱:ka@2025.net
            </div>
          </div>
        </div>
        <div class="qrcode">
          <div style="margin-right: 52px">
            <img src="@/assets/image/qrcode1.png" alt="">
            <div>公众号</div>
          </div>
          <div style="margin-right: 52px">
            <img src="@/assets/image/qrcode2.png" alt="">
            <div>视频号</div>
          </div>
          <div>
            <img src="@/assets/image/qrcode3.png" alt="">
            <div>小程序</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-b">
      <ul class="footer-b-view">
        <li class="fast-item"><a href="http://www.sinomach.com.cn/" target="_blank">中国机械工业集团有限公司</a></li>
        <li class="fast-item"><a href="http://www.chtgc.com/" target="_blank">中国恒天集团有限公司</a></li>
        <li class="fast-item"><a href="http://www.jwgf.com/" target="_blank">经纬纺织机械股份有限公司</a></li>
        <li class="fast-item"><a href="http://www.zzfj.com/" target="_blank">恒天重工股份有限公司</a></li>
        <li class="fast-item last"><a href="https://www.fongs.com/" target="_blank">中国恒天立信国际有限公司</a></li>
      </ul>
      <div class="footer-copyright">Copyright © 纺友技术有限公司    版权所有 ICP证：鄂ICP备09014919号-19</div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.view {
  color: #ffffff;
  margin-top: 180px;

  .footer-a {
    padding: 40px 360px 0 360px;
    height: 344px;
    background-color: #323A49;

    .footer-content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo-2 {
        margin-bottom: 57px;

        img {
          width: 104px;
          height: 46px;
        }
      }

      .qrcode {
        display: flex;

        > div {
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 117px;
            height: 117px;
            margin-bottom: 10px;
          }
        }
      }
    }


  }

  .footer-b {
    box-sizing: border-box;
    padding: 23px 360px;
    background-color: #4A5364;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .footer-b-view {
      margin-bottom: 23px;
      list-style: none;
      display: flex;

      li {
        box-sizing: border-box;
        padding: 0 18px;
        position: relative;

        a, a:visited {
          text-decoration: none;
          color: inherit;
        }
      }

      li::after {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          width: 1px;
          height: 100%;
          background: #fff;
      }

      .last::after {
        background: unset;
      }
    }
  }
}
</style>
