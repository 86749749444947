<template>
  <div class="header contanier">
    <div class="logo">
      <img src="@/assets/image/logo.png" alt="">
    </div>
    <div class="menu">
      <div v-for="(item, index) in menuList" :key="index" :class="activeMenu == index ? 'active' : ''">
        <template v-if="index === 2">
          <div class="menu-item" @mouseenter="onHover(index)" @mouseleave="onLeave()">{{item.name}}</div>
          <div class="digitize-hover" @mouseenter="onHover(index)" @mouseleave="onLeave()" v-show="hover === index">
            <div v-for="(value, i) in mesList" :key="i" :class="activeMes === (i + 1) ? 'hover-item-active' : ''" @click="onMesActive(value, i + 1)">{{value.name}}</div>
          </div>
        </template>
        <template v-else-if="index === 1">
          <div class="menu-item" @mouseenter="onHoverMedia(index)" @mouseleave="onLeaveMedia()">{{item.name}}</div>
          <div class="digitize-hover" @mouseenter="onHoverMedia(index)" @mouseleave="onLeaveMedia()" v-show="hoverMedia === index">
            <div v-for="(value, i) in mediaList" :key="i" :class="activeMedia === (i + 1) ? 'hover-item-active' : ''" @click="onMediaActive(value, i + 1)">{{value.name}}</div>
          </div>
        </template>
        <template v-else>
          <div class="menu-item" @click="onActive(item, index)">{{item.name}}</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuList: [
        {
          name: '首页',
          route: '/'
        },
        {
          name: '新媒体服务',
          route: '/media'
        },
        {
          name: '数字化服务',
          route: '/digitize'
        },
        {
          name: '客户案例',
          route: '/customer'
        },
        {
          name: '新闻资讯',
          route: '/news'
        },
        {
          name: '关于纺友',
          route: '/aboutUs'
        }
      ],
      mesList: [
        {
          name: '棉纺MES',
          route: '/mf'
        },
        {
          name: '织造MES',
          route: '/zz'
        },
        {
          name: '玻纤MES',
          route: '/bx'
        },
        {
          name: '无纺布MES',
          route: '/wfb'
        },
        {
          name: '印染MES',
          route: '/yr'
        },
      ],
      mediaList: [
        {
          name: '宣传片',
          route: '/xc'
        },
        {
          name: '3D动画',
          route: '/3d'
        },
        {
          name: 'VR漫游',
          route: '/vr'
        },
        {
          name: '虚拟仿真教学',
          route: '/xn'
        },
      ],
      activeMenu: JSON.parse(window.localStorage.getItem('menuIndex')) || 0,
      activeMes: JSON.parse(window.localStorage.getItem('activeMes')) || null,
      activeMedia: JSON.parse(window.localStorage.getItem('activeMedia')) || null,
      hover: 0,
      hoverMedia: 0,
    }
  },
  methods: {
    onActive(item, index) {
      this.activeMedia = null
      this.activeMes = null
      this.$router.push(item.route)
      window.localStorage.setItem('menuIndex', index)
      this.activeMenu = index
    },
    onMesActive(item, index) {
      this.$router.push(item.route)
      window.localStorage.setItem('menuIndex', 2)
      this.activeMenu = 2

      window.localStorage.setItem('activeMes', index)

      window.localStorage.removeItem('activeMedia')
      this.activeMedia = null
      this.activeMes = index
    },
    onMediaActive(item, index) {
      this.$router.push(item.route)
      window.localStorage.setItem('menuIndex', 1)
      this.activeMenu = 1

      window.localStorage.setItem('activeMedia', index)

      window.localStorage.removeItem('activeMes')
      this.activeMes = null
      this.activeMedia = index
    },
    onHoverMedia(index) {
      this.hoverMedia = index
    },
    onLeaveMedia() {
      this.hoverMedia = null
    },
    onHover(index) {
      this.hover = index
    },
    onLeave() {
      this.hover = null
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  // height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  // padding: 15px 0;

  img {
    width: 104px;
    height: 46px;
  }

  .menu {
    display: flex;
    // flex: 1;

    > div {
      position: relative;

      .menu-item {
        width: 120px;
        line-height: 80px;
        font-size: 15px;
        text-align: center;
        cursor: pointer;
      }

      .digitize-hover {
        width: 120px;
        height: 170px;
        background: #ffffff;
        position: absolute;
        bottom: -170px;
        display: flex;
        flex-direction: column;
        z-index: 99999;

         > div {
          flex: 1;
          font-size: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #333333;
          cursor: pointer;
         }

      }
    }
  }
}

.active {
  color: #ffffff;
  background: #1676FF;
}

.hover-item-active {
  color: #1676FF !important;
}

.contanier {
  padding: 0 360px;
}
</style>
